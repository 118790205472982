import { Suspense, lazy } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { esES as LocaleDataGrid } from "@mui/x-data-grid";
import { ApiInterceptor } from "./http/environment";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/montserrat";

Amplify.configure(awsconfig);
const theme = createTheme(
  {
    typography: {
      fontFamily: "Montserrat, sans-serif",
      h1: {
        fontWeight: 500,
        color: "#333",
        textTransform: "uppercase",
        letterSpacing: "1.5px",
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        marginBottom: "1rem",
      },
    },
    palette: {
      customColor: {
        main: "#111135",
      },
    },
  },
  esES,
  LocaleDataGrid
);

ApiInterceptor();

const App = () => {
  const DetalleProyecto = lazy(() => import("./pages//DetalleProyecto"));
  const Login = lazy(() => import("./pages//Login"));
  const Registro = lazy(() => import("./pages//Registro"));
  const CambioContraseña = lazy(() => import("./pages//CambioContraseña"));
  const VerificarCorreo = lazy(() => import("./pages//VerificarCorreo"));
  const Nosotros = lazy(() => import("./pages//Nosotros"));
  const DetalleLote = lazy(() => import("./pages//DetalleLote"));
  const DetalleReserva = lazy(() => import("./pages//DetalleReserva"));

  return (
    <Suspense
      fallback={
        <Spinner full>
          <h6>Cargando...</h6>
        </Spinner>
      }
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route element={<Login />} path="/login" exact />
                    <Route
                      element={<VerificarCorreo />}
                      path="/verificarcuenta"
                      exact
                    />
                    <Route element={<Registro />} path="/registrarse" exact />
                    <Route
                      element={<CambioContraseña />}
                      path="/cambiarcontraseña"
                      exact
                    />
                  </Route>
                  <Route element={<Nosotros />} path="/nosotros" exact />
                  <Route element={<DetalleProyecto />} path="/" exact />
                  <Route element={<DetalleLote />} path="/detallelote" exact />
                  <Route
                    element={<DetalleReserva />}
                    path="/detallarreserva"
                    exact
                  />
                  <Route element={<Navigate to={"/"} />} path="*" exact />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
};

export default App;
